import { Assignment } from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOn';
import { Autocomplete, InputAdornment } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { CancelButton, PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import { generateAlarmDescription, isEmail } from 'helper/helperFunctions';
import { SiteAlarmRetrieve } from 'interfaces/models/SiteAlarmRetrieve';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { queryKeys } from 'rq/constants';
import { useCreateAlarm, useUpdateAlarm } from 'rq/hooks/alarmsHook';
import { useGetAllSites } from 'rq/hooks/sitesHook';
import { useStations } from 'rq/hooks/stationHook';
import useDebounce from 'rq/hooks/useDebounce';
import { useGetUsers } from 'rq/hooks/userHook';
import { queryClient } from 'rq/queryClient';
import * as Yup from 'yup';

const AlarmsCreateUpdateModal = () => {
	const { t } = useTranslation();
	const orgId = useGlobalStore((state) => state.currentOrganization.id);
	const open = useGlobalStore((state) => state.createUpdateAlarmModal.open);
	const alarm = useGlobalStore((state) => state.createUpdateAlarmModal.alarm);
	const siteId = useGlobalStore((state) => state.createUpdateAlarmModal.siteId);
	const stationId = useGlobalStore((state) => state.createUpdateAlarmModal.stationId);
	const toggleCreateUpdateAlarmModal = useGlobalStore(
		(state) => state.toggleCreateUpdateAlarmModal
	);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const { data: sites, isLoading: sitesLoading } = useGetAllSites();
	const { mutate: createAlarm } = useCreateAlarm();
	const { mutate: updateAlarm } = useUpdateAlarm();

	const [filter, setFilter] = useState('');
	const [usersDescription, setUsersDescription] = useState(alarm?.description);
	const debouncedSearchQuery = useDebounce(filter);

	const { data: users } = useGetUsers({
		orgId: orgId ?? 0,
		search: debouncedSearchQuery
	});

	const validationSchema = Yup.object().shape({
		description: Yup.string().required(t('ALARM_DESCRIPTION_REQUIRED')).max(255),
		site: Yup.number().required('REQUIRED'),
		alarm_type: Yup.string().required(t('ALARM_TYPE_REQUIRED')),

		interval: Yup.number().when('alarm_type', {
			is: 'NO_MEAS_ARRIVING',
			then: Yup.number().required(t('FIELD_REQUIRED')).min(60, t('ALARM_INTERVAL_MIN_VALUE'))
		}),
		variable: Yup.string().when('alarm_type', {
			is: (alarmType: string) => alarmType === 'LOWER_THAN' || alarmType === 'HIGHER_THAN',
			then: Yup.string().required(t('ALARM_VARIABLE_REQUIRED'))
		}),
		value: Yup.number().when('alarm_type', {
			is: (alarmType: string) => alarmType === 'LOWER_THAN' || alarmType === 'HIGHER_THAN',
			then: Yup.number().required(t('ALARM_VALUE_REQUIRED'))
		}),
		emails: Yup.string()
			.max(255)
			.required(t('ALARM_EMAIL_REQUIRED'))
			.test(
				'emails',
				t('ALARM_EMAIL_INVALID'),
				(value) => (value && value.split(';').every(isEmail)) as boolean
			)
	});

	const formik = useFormik({
		initialValues: {
			description: alarm?.description ?? '',
			organization: orgId as number,
			site: (alarm?.site as number) ?? siteId,
			station: (alarm?.station as number) ?? stationId,
			value: alarm?.parameters.value ?? 0,
			variable: alarm?.parameters.variable ?? 'VELOCITY',
			alarm_type: alarm?.alarm_type ?? ('NO_MEAS_ARRIVING' as SiteAlarmRetrieve.alarm_type),
			interval: alarm?.alarm_type === 'NO_MEAS_ARRIVING' ? alarm.parameters.interval : 60,
			emails: alarm?.email ?? '',
			users: alarm?.users ?? []
		},
		validationSchema: validationSchema,
		onSubmit: async (data) => {
			const submitData = {
				alarm_type: data.alarm_type,
				description: data.description,
				email: data.emails,
				parameters:
					data.alarm_type === 'NO_MEAS_ARRIVING'
						? { interval: data.interval }
						: { value: data.value, variable: data.variable },
				site: data.site,
				station: data.station,
				organization: data.organization,
				users: data.users
			};
			if (!alarm) {
				createAlarm(submitData, {
					onSuccess: () => {
						queryClient.invalidateQueries(queryKeys.alarms);
						toast.success(t('ALARM_SAVED'));
						formik.resetForm();
						toggleCreateUpdateAlarmModal({
							open: false,
							alarm: null,
							siteId: null,
							stationId: null
						});
					}
				});
			} else {
				updateAlarm(
					{ alarmId: alarm.id.toString(), data: submitData },
					{
						onSuccess: () => {
							queryClient.invalidateQueries(queryKeys.alarms);
							toast.success(t('ALARM_SAVED'));
							formik.resetForm();
							toggleCreateUpdateAlarmModal({
								open: false,
								alarm: null,
								siteId: null,
								stationId: null
							});
						}
					}
				);
			}
		}
	});

	const { data: stations, isLoading: stationsLoading } = useStations(Number(formik.values.site), {
		enabled: !!formik.values.site
		/*	onSuccess: (data) => {
					data.length === 1 && setSelectedStation(data[0]);
				}*/
	});

	const closeModal = () => {
		if (formik.dirty) {
			toggleConfirmationDialog({
				dialogOpen: true,
				headerTitle: t('UNSAVED_CHANGES_TITLE'),
				message: t('UNSAVED_CHANGES'),
				confirmAction: () => {
					toggleCreateUpdateAlarmModal({ open: false, alarm: null, siteId: null, stationId: null });
				},
				type: 'confirmation'
			});
		} else {
			toggleCreateUpdateAlarmModal({ open: false, alarm: null, siteId: null, stationId: null });
		}
	};

	useEffect(() => {
		if (!usersDescription)
			formik.setFieldValue(
				'description',
				generateAlarmDescription({
					alarmVariable: formik.values.variable,
					alarmInterval: formik.values.interval,
					alarmType: formik.values.alarm_type,
					alarmValue: formik.values.value
				})
			);
	}, [
		formik.values.alarm_type,
		formik.values.interval,
		formik.values.value,
		formik.values.variable
	]);

	return (
		<Modal
			open={open}
			closeModal={closeModal}
			modalTitle={alarm ? t('EDIT_ALARM') : t('CREATE_ALARM')}>
			<form
				className="flex flex-col gap-md px-4 py-4 md:px-8 md:py-8"
				onSubmit={formik.handleSubmit}
				autoComplete="off">
				<Autocomplete
					options={sites ?? []}
					getOptionLabel={(option) => option.name}
					renderOption={(props, option) => {
						return (
							<li {...props} key={option.site_id}>
								{option.name}
							</li>
						);
					}}
					loading={sitesLoading}
					value={sites?.find((site) => site.site_id === formik.values.site) || null}
					onChange={(event, newValue) => {
						formik.setFieldValue('site', newValue ? newValue.site_id : '');
					}}
					isOptionEqualToValue={(option, value) => option.site_id === value.site_id}
					renderInput={(params) => (
						<TextField
							{...params}
							required
							label={t('SITE')}
							InputProps={{ ...params.InputProps, startAdornment: <LocationIcon /> }}
						/>
					)}
				/>

				<Autocomplete
					id="alarm_station_autocomplete"
					options={stations ?? []}
					getOptionLabel={(option) =>
						option.station_name && option.station_name !== ''
							? `${option.station_name} (${option.station_id})`
							: `${option.station_type.replaceAll('_', ' ')} (${option.station_id})`
					}
					loading={stationsLoading}
					value={stations?.find((station) => station.station_id === formik.values.station) || null}
					onChange={(event, newValue) => {
						formik.setFieldValue('station', newValue ? newValue.station_id : '');
					}}
					isOptionEqualToValue={(option, value) => option.station_id === value.station_id}
					renderInput={(params) => (
						<TextField
							{...params}
							required
							label={t('STATION')}
							name="station"
							InputProps={{ ...params.InputProps, startAdornment: <Assignment /> }}
						/>
					)}
				/>
				{/*				<div className="min-h-9 text-sm font-light"></div>*/}

				<FormControl required>
					<InputLabel id="demo-simple-select-required-label">{t('ALARM_TYPE')}</InputLabel>
					<Select
						labelId="demo-simple-select-required-label"
						id="demo-simple-select-required"
						value={formik.values.alarm_type}
						label={t('ALARM_TYPE')}
						name="alarm_type"
						onChange={formik.handleChange}>
						{Object.keys(SiteAlarmRetrieve.alarm_type).map((type) => {
							type ObjectKey = keyof typeof SiteAlarmRetrieve.alarm_type;
							const myKey = `${type}` as ObjectKey;
							return (
								<MenuItem key={type} value={myKey}>
									{t(SiteAlarmRetrieve.alarm_type[myKey])}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>

				{formik.values.alarm_type === 'NO_MEAS_ARRIVING' && (
					<div>
						{' '}
						<TextField
							inputProps={{ type: 'number' }}
							id="interval"
							className={'w-full'}
							required
							value={formik.values.interval}
							label={t('ALARM_INTERVAL')}
							name="interval"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							error={formik.touched.interval && Boolean(formik.errors.interval)}
						/>
						<div className="mt-1 text-sm font-light">
							<div className="text-red-500">
								{formik.touched.interval &&
									typeof formik.errors.interval === 'string' &&
									formik.errors.interval}
							</div>
							<div className="">{t('ALARM_INTERVAL_HINT')}</div>
						</div>
					</div>
				)}
				{(formik.values.alarm_type === 'LOWER_THAN' ||
					formik.values.alarm_type === 'HIGHER_THAN') && (
					<div className={'flex flex-row items-center justify-evenly'}>
						{' '}
						<TextField
							select
							className="w-full"
							id="variable"
							required
							value={formik.values.variable}
							label={t('VARIABLE')}
							name="variable"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							error={formik.touched.variable && Boolean(formik.errors.variable)}>
							{Object.keys(SiteAlarmRetrieve.alarm_variable_type).map((type) => {
								type ObjectKey = keyof typeof SiteAlarmRetrieve.alarm_variable_type;
								const myKey = `${type}` as ObjectKey;
								return (
									<MenuItem key={type} value={myKey}>
										{t(SiteAlarmRetrieve.alarm_variable_type[myKey])}
									</MenuItem>
								);
							})}
						</TextField>
						<div className={'items-center text-center text-2xl'}>
							{formik.values.alarm_type === 'LOWER_THAN' ? '<' : '>'}
						</div>
						<TextField
							inputProps={{ type: 'number' }}
							className=" w-full"
							id="variable_value"
							required
							value={formik.values.value}
							label={t('VALUE')}
							name="value"
							onBlur={formik.handleBlur}
							onChange={formik.handleChange}
							error={formik.touched.value && Boolean(formik.errors.value)}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										{formik.values.variable === 'LEVEL' && 'm'}
										{formik.values.variable === 'DISCHARGE' && 'm3/s'}
										{formik.values.variable === 'VELOCITY' && 'm/s'}
									</InputAdornment>
								)
							}}
						/>
					</div>
				)}

				<div>
					<TextField
						required
						onBlur={formik.handleBlur}
						id="emails"
						value={formik.values.emails}
						className="w-full"
						label={t('ALARM_EMAIL')}
						name="emails"
						onChange={formik.handleChange}
						error={formik.touched.emails && Boolean(formik.errors.emails)}
					/>
					<div className=" mt-1 text-sm font-light">
						<div className="text-red-500">{formik.touched.emails && formik.errors.emails}</div>
						<div>{t('ALARM_EMAIL_HINT')}</div>
					</div>
				</div>

				<TextField
					required
					className=""
					id="description"
					value={formik.values.description}
					label={t('ALARM_DESCRIPTION')}
					name="description"
					onChange={(e) => {
						formik.handleChange(e);
						setUsersDescription(e.target.value);
					}}
					onBlur={formik.handleBlur}
					error={formik.touched.description && Boolean(formik.errors.description)}
				/>

				<div>
					<Autocomplete
						freeSolo
						multiple
						id="alarm-user-select"
						value={formik.values.users}
						options={users ? users.results ?? users : []}
						getOptionLabel={(option) => {
							if (
								typeof option === 'object' &&
								option !== null &&
								'first_name' in option &&
								'last_name' in option &&
								'username' in option
							) {
								return `${option.first_name} ${option.last_name} - ${option.username}`;
							}
							return '';
						}}
						isOptionEqualToValue={(option, value) => {
							return option.id === value.id;
						}}
						filterSelectedOptions
						onChange={(e, value) => {
							formik.setFieldValue('users', value);
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								label={t('SEARCH_USERS')}
								onChange={(e) => setFilter(e.target.value)}
							/>
						)}
					/>
					<div className="mt-1 text-sm font-light">
						<div>{t('ALARM_USERS_HINT')}</div>
					</div>
				</div>

				<div className=" mb-2 flex flex-row justify-center gap-md">
					<CancelButton onClick={closeModal} text={t('CANCEL')} />
					<PrimaryButton
						disabled={!formik.isValid || !formik.dirty}
						type={'submit'}
						text={alarm ? t('SAVE') : t('CREATE')}
					/>
				</div>
			</form>
		</Modal>
	);
};

export default AlarmsCreateUpdateModal;
